'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.assets.factory:AssetDataManager

 # @description

###
angular
  .module 'mundoAdmin.assets'
  .factory 'AssetDataManager', [
    'MundoDefaultManager'
    'ContactManager'
    '$log'
    (
      MundoDefaultManager
      ContactManager
      $log
    ) ->
      AssetDataManagerBase = new MundoDefaultManager()
      AssetDataManagerBase.setUrl('assets/data/types')

      AssetDataManagerBase.one = (type) ->
        @transport.one('assets/data', type).get()

      AssetDataManagerBase.getList = (assetTypeCode)->

        $log.debug 'typeCode': assetTypeCode
        types = []

        @transport.all(@conf.url).customGET("" ,{typeCode: assetTypeCode}).then((data) ->
          for k,v of data.results
            types.push({type:v})
          types
        )
        types

      AssetDataManagerBase.new = (assetId, data) ->
        @transport.one('assets', assetId).all("data/#{data.type}").post(_.pick(data, @getObject(data.type)))

      AssetDataManagerBase.removeData = (assetId, dataId) ->
        @transport.one('assets', assetId).one('data', dataId).remove()

  #   defaultType
      defaultForm =
        [
          key: 'value'
          name: 'value'
          type: 'input'
          templateOptions:
            label: 'Value'
            placeholder: 'value'
            required: true
        ]

      AssetDataManagerBase.setExtraForm('default', defaultForm)
      AssetDataManagerBase.setObject('default', ['value'])
  #   type selector
      dataTypeForm =
        [
          key: 'assetDataType'
          name: 'assetDataType'
          type: 'select'
          templateOptions:
            label: 'Data type'
            placeholder: 'Data type'
            required: true
            labelProp: "type"
            valueProp: "type"
            options: AssetDataManagerBase.getList()
        ]

      AssetDataManagerBase.setExtraForm('dataType', dataTypeForm)

  #   contactType
      contactDataForm =
        [
          key: 'value'
          name: 'value'
          type: 'select'
          templateOptions:
            label: 'Contact'
            placeholder: 'contact'
            required: true
            labelProp: "label"
            valueProp: "id"
            options: ContactManager.getFullList({'sort' : 'label,ASC'}).$object
        ]

      AssetDataManagerBase.setExtraForm('Contact', contactDataForm)

  #   contactType
      mapMarkerDataForm =
        [
          key: 'color'
          name: 'color'
          type: 'color'
          defaultValue: '#000000'
          ngModelAttrs:
            style:
              attribute: 'style'
          templateOptions:
            required: true
            style: 'width: 70px'
        ,
          key: 'marker'
          name: 'marker'
          type: 'input'
          templateOptions:
            label: 'Marker'
            placeholder: 'Marker'
            required: true
        ]

      AssetDataManagerBase.setExtraForm('MapMarker', mapMarkerDataForm)
      AssetDataManagerBase.setObject('MapMarker', ['color', 'marker'])

  #   contactType
      PoliceVehicleDataForm =
        [
          key: 'isUnmarked'
          name: 'isUnmarked'
          type: 'checkbox'
          ngModelAttrs:
            style:
              attribute: 'style'
          templateOptions:
            label: 'isUnmarked'
            required: false
            style: 'margin-top: 40px; margin-left: 10px; margin-right: 10px'
        ,
          key: 'roofNumber'
          name: 'roofNumber'
          type: 'input'
          templateOptions:
            label: 'roofNumber'
            placeholder: 'roofNumber'
            required: true
        ]

      AssetDataManagerBase.setExtraForm('PoliceVehicle', PoliceVehicleDataForm)
      AssetDataManagerBase.setObject('PoliceVehicle', ['isUnmarked', 'roofNumber'])
      AssetDataManagerBase
  ]
